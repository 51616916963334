import clsx from 'clsx';
import { useFormik } from 'formik';
import * as React from 'react';
import { FC, ReactNode, useMemo } from 'react';
import Select, { CommonProps, NamedProps } from 'react-select';

import './SelectInput.css';

export type SelectInputValue = {
  value: any;
  label: ReactNode;
  buttonLabel?: ReactNode;
  isDisabled?: boolean;

  options?: SelectInputValue[];
};

export type SelectInputProps = {
  className?: string;
  selectClassName?: string;

  formik: ReturnType<typeof useFormik>;
  name: string;
  label?: string;
  placeholder?: string;
  values: SelectInputValue[];
  disabled?: boolean;
  showErrors?: boolean;

  reactSelectProps?: Partial<React.ComponentProps<typeof Select>>;
};

const SelectInput: FC<SelectInputProps> = ({
  className,
  selectClassName,

  formik,
  name,
  label,
  placeholder,
  values,
  disabled,
  showErrors = true,

  reactSelectProps = {},
  children,
}) => {
  const options = useMemo(() => values, [values]);

  return (
    <>
      <div
        className={clsx(
          'SelectInput-wrapper',
          className,
          disabled && 'opacity-50'
        )}
      >
        {label && (
          <label
            htmlFor={name}
            className={clsx('block text-sm mb-2', disabled && 'opacity-50')}
          >
            {label}
          </label>
        )}

        <div className="flex">
          <Select
            className={clsx(
              'ReactSelect',
              disabled && 'disabled',
              selectClassName
            )}
            classNamePrefix="ReactSelect"
            menuPlacement="auto"
            value={formik.values[name]}
            onChange={(val) => formik.setFieldValue(name, val)}
            placeholder={placeholder ?? ''}
            disabled={disabled}
            options={options}
            formatOptionLabel={(value, { context }) =>
              context === 'menu'
                ? value.label
                : value.buttonLabel ?? value.label
            }
            {...reactSelectProps}
          />
          {children}
        </div>
        {showErrors && formik.touched[name] && formik.errors[name] ? (
          <div className="text-error">{formik.errors[name]}</div>
        ) : null}
      </div>
    </>
  );
};

export default SelectInput;
